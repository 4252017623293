@media print {
	// .safaVisa-wrapper {
	// 	.safa-visa-container {
	// 		border-radius: 8px;
	// 		padding: 24px;
	// 		background-color: #ffffff;
	// 		width: 100% !important;
	// 	}

	// 	.main-title {
	// 		font-family: Cairo;
	// 		font-weight: 700;
	// 		font-size: 20px;
	// 		color: #2c302e;
	// 	}

	// 	.message-title {
	// 		font-family: Cairo;
	// 		font-weight: 400;
	// 		font-size: 16px;
	// 		color: #707170;
	// 	}

	// 	.our-border {
	// 		border-radius: 10px;
	// 	}

	// 	thead {
	// 		background-color: #eeeeee;
	// 		height: 58px;
	// 		th {
	// 			color: #707170;
	// 		}
	// 	}

	// 	td {
	// 		font-size: 14px;
	// 		font-weight: 600;
	// 	}

	// 	tr {
	// 		height: 58px !important;
	// 	}

	// 	// .page-item {
	// 	// 	border-color: transparent !important;
	// 	// 	border-radius: 6px !important;
	// 	// 	.page-link {
	// 	// 		background-color: #f7f6f6 !important;
	// 	// 		padding: 0.75rem !important;
	// 	// 	}
	// 	// }

	// 	.active-status {
	// 		padding: 5px 20px;
	// 		border-radius: 5px;
	// 		background-color: #ecf7f0;
	// 		color: #57a866;
	// 		width: fit-content;
	// 	}

	// 	.notActivated-status {
	// 		padding: 5px 20px;
	// 		background-color: #fbf2f2;
	// 		border-radius: 5px;
	// 		color: #ea5455;
	// 		width: fit-content;
	// 	}

	// 	.id-text {
	// 		color: #1e85ff;
	// 	}

	// 	.btn {
	// 		display: flex;
	// 		gap: 8px;
	// 		justify-content: center;
	// 		align-items: center;
	// 		width: 120px;
	// 		height: 48px;
	// 		font-weight: 700;
	// 		font-size: 16px;
	// 		border-radius: 4px;
	// 	}

	// 	.add-btn {
	// 		background-color: #1e85ff;
	// 		color: white;
	// 	}

	// 	.export-btn {
	// 		background-color: #ecf7f0;
	// 		color: #57a866;
	// 		width: 160px !important;
	// 		border: 1px solid #b4dbbb !important;
	// 	}

	// 	.print-btn {
	// 		background-color: #fbf2f2;
	// 		color: #ea5455;
	// 		width: 170px !important;
	// 		border: 1px solid #eac9c9 !important;
	// 	}

	// 	.back-btn {
	// 		background-color: #eaf3ff !important;
	// 		color: #1e85ff !important;
	// 		border: 1px solid #b5d7ff !important;
	// 		font-weight: 600;
	// 		svg {
	// 			transform: rotate(180deg);
	// 		}
	// 	}

	// 	.icon-wrapper {
	// 		width: 48px;
	// 		height: 48px;
	// 		background-color: #fbf2f2;
	// 		display: flex;
	// 		align-items: center;
	// 		justify-content: center;
	// 		border-radius: 50%;
	// 	}

	// 	.delete-btn {
	// 		height: 50px;
	// 		border-radius: 8px;
	// 		background: #ea5455;
	// 		font-family: Cairo;
	// 		font-weight: 700;
	// 		font-size: 16px;
	// 		color: #fff;
	// 	}

	// 	.cancel-btn {
	// 		height: 50px;
	// 		border-radius: 8px;
	// 		background: #eeeeee;
	// 		font-family: Cairo;
	// 		font-weight: 700;
	// 		font-size: 16px;
	// 		color: #707170;
	// 	}

	// 	.react-switch-bg {
	// 		// background-color: #57a866 !important;
	// 		path[fill-rule="evenodd"] {
	// 			display: none !important;
	// 		}
	// 	}

	// 	.dropdown {
	// 		.dropdown-menu {
	// 			background-color: white;
	// 			color: #707170;
	// 			left: -150px !important;
	// 		}

	// 		.dropdown-item {
	// 			color: #707170;
	// 			padding: 8px 12px;
	// 			svg {
	// 				margin: 0px 10px 0px 0px;
	// 			}
	// 		}

	// 		.dropdown-item:hover {
	// 			background-color: #eaf3ff !important;
	// 			color: #1e85ff !important;
	// 			path {
	// 				stroke: #1e85ff !important;
	// 			}
	// 		}

	// 		.btn {
	// 			width: fit-content;
	// 			padding: 0px;
	// 			box-shadow: none;
	// 			height: auto;
	// 		}

	// 		.btn-secondary,
	// 		.btn-secondary.active,
	// 		.btn-secondary.dropdown-toggle {
	// 			background: transparent !important;
	// 		}

	// 		.btn-secondary.dropdown-toggle:focus {
	// 			box-shadow: none;
	// 		}
	// 	}

	// 	.pricing-info-holder {
	// 		display: flex;
	// 		align-items: center;
	// 		flex-wrap: wrap;
	// 		row-gap: 30px;
	// 		// column-gap: 90px;
	// 		& > div {
	// 			min-width: 200px;
	// 		}
	// 		#code,
	// 		.left-border {
	// 			min-width: 200px;
	// 			padding: 12px 0px 12px 8px;
	// 			border-left: 2px solid #eeeeee;
	// 		}
	// 		.title {
	// 			font-weight: 600;
	// 			font-size: 16px;
	// 			color: #707170;
	// 		}
	// 		.subTitle {
	// 			font-weight: 400;
	// 			font-size: 14px;
	// 			color: #2c302e;
	// 		}
	// 	}

	// 	.more-info-toolTip {
	// 		margin: 0px 4px;
	// 		font-size: 14px;
	// 		width: 26px;
	// 		height: 26px;
	// 		display: flex;
	// 		justify-content: center;
	// 		align-items: center;
	// 		border-radius: 49px;
	// 		background-color: #eaf3ff;
	// 		color: #1e85ff;
	// 		font-weight: 600;
	// 	}

	// 	.safa-visa-filter {
	// 		.filter-tags-holder {
	// 			display: flex;
	// 			gap: 10px;
	// 			justify-content: center;
	// 			align-items: center;
	// 		}
	// 		.tag {
	// 			width: fit-content !important;
	// 			padding: 10px 16px;
	// 			border-radius: 40px;
	// 			border: 1px solid #dddcdc;
	// 			font-size: 16px;
	// 			font-weight: 600;
	// 			color: #707170;
	// 			cursor: pointer;
	// 		}
	// 		.filter-btn {
	// 			background-color: #eaf3ff !important;
	// 			color: #1e85ff !important;
	// 			border: 1px solid #b5d7ff !important;
	// 			font-weight: 600;
	// 		}
	// 		.active-tag {
	// 			@extend .tag;
	// 			background-color: #eaf3ff;
	// 			border: 1px solid #b5d7ff;
	// 			color: #1e85ff;
	// 			cursor: pointer;
	// 		}
	// 	}

	// 	.control-field__body {
	// 		padding: 0px;
	// 	}

	// 	.control-field__label {
	// 		font-weight: 400;
	// 		font-size: 14px;
	// 		color: #707170;
	// 	}
	// 	.control-field__input,
	// 	.control-field__select,
	// 	.all-date-picker,
	// 	.rmsc .dropdown-container {
	// 		height: 48px;
	// 		border-radius: 6px;
	// 		border: 1px solid #eeeeee;
	// 	}

	// 	.control-field__select {
	// 		@extend .control-field__input;
	// 		padding: 14px 10px 0px 6px;
	// 		width: 100%;
	// 	}

	// 	.SingleDatePickerInput {
	// 		height: 100%;
	// 		.DateInput_input {
	// 			padding: 15px 11px 9px !important;
	// 		}
	// 	}

	// 	.control-field__body .date-icon {
	// 		right: 2px;
	// 		bottom: 6px;
	// 	}

	// 	.control-field--danger {
	// 		border: none !important ;
	// 		.control-field__input,
	// 		.control-field__select {
	// 			border: 1px solid #ff5f59 !important;
	// 		}
	// 		.control-field__label,
	// 		.radio-group-label {
	// 			color: #ff5f59 !important;
	// 		}
	// 	}

	// 	.control-field__feedback--danger {
	// 		.rmsc .dropdown-container {
	// 			border: 1px solid #ff5f59 !important;
	// 		}
	// 	}
	// 	.rmsc .dropdown-heading {
	// 		height: 100% !important;
	// 	}

	// 	.dropdown-content {
	// 		color: #2c302e !important;
	// 		input[type="checkbox"] {
	// 			accent-color: #1e85ff;
	// 			width: 15px !important;
	// 			height: 15px !important;
	// 		}
	// 	}

	// 	.tabs-holder {
	// 		display: flex;
	// 		align-items: center;
	// 		justify-content: flex-start;
	// 		border-bottom: 1px solid #f7f6f6;

	// 		.tab {
	// 			display: flex;
	// 			align-items: center;
	// 			justify-content: flex-start;
	// 			padding: 16px;
	// 			border: none !important;
	// 			border-radius: 0px !important;
	// 			font-weight: 600;
	// 			font-size: 16px;
	// 			color: #9c9fab;
	// 			cursor: pointer !important;
	// 		}
	// 		.active-tab {
	// 			@extend .tab;
	// 			border-bottom: 3px solid #1e85ff !important;
	// 			color: #1e85ff;
	// 			svg path {
	// 				stroke: #1e85ff;
	// 			}
	// 		}
	// 		.disabled-tab {
	// 			pointer-events: none;
	// 			cursor: not-allowed !important;
	// 		}
	// 	}

	// 	.radio-group-holder {
	// 		.radio-group-label {
	// 			margin-bottom: 2px;
	// 			font-weight: 400;
	// 			font-size: 14px;
	// 			color: #707170;
	// 		}
	// 		.inputs-holder {
	// 			height: 48px;
	// 			border-radius: 8px;
	// 			padding: 11px 12px;
	// 			background-color: #f8f7fa;
	// 			display: flex;
	// 			align-items: center;
	// 			gap: 30px;
	// 			label {
	// 				@extend .radio-group-label;
	// 				color: #2c302e;
	// 				display: flex;
	// 				align-items: center;
	// 				gap: 5px;
	// 				margin-bottom: 0px !important;
	// 				cursor: pointer !important;
	// 			}
	// 		}
	// 	}

	// 	.custom-field {
	// 		textarea {
	// 			height: auto !important;
	// 		}
	// 	}

	// 	.relative-btn {
	// 		position: absolute;
	// 		top: -80px;
	// 		right: 0px;
	// 	}

	// 	.modal-content {
	// 		border-radius: 12px !important;
	// 	}
	// }
	@import "pricing";
	.breadcrumb-app {
		display: none !important;
	}
	.btn {
		display: none !important;
	}
}

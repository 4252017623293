@import "../../base/variables";

#sideNav {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100%;
	width: 70px;
	top: 60px;
	left: 0;
	background-color: #2c302e;
	text-align: center;
	// box-shadow: 0px 2px 12px #0000003E;
	z-index: 1001;
}

[dir="rtl"] #sideNav {
	right: 0;
	text-align: right;
}

#sideNav.opend {
	width: $opendNav;
}
#sideNav.hidden {
	display: none;
}

.scrollable-div::-webkit-scrollbar {
	position: absolute;
	left: 0; /* Position the scrollbar on the right of the element*/
	width: 0px;
}

.sidenav_list {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: start;
	width: 100%;
	text-align: left;
	padding: 0;
	overflow-y: auto;
	overflow-x: hidden !important;
	height: 90vh;
	padding-bottom: 0px !important;
}
.sidenav_list .sidenav_dropdown {
	padding: 0;
	margin-inline-start: 27px;
}

::-webkit-scrollbar {
	width: 8px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
	background: #171817; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
	background-color: #5a5656; /* color of the scroll thumb */
	border-radius: 20px; /* roundness of the scroll thumb */
	border: 2px solid #2c302e; /* creates padding around scroll thumb */
}

.sidenav_item {
	position: relative;
	display: block;
}

.sidenav_link {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: start;
	width: 100%;
	padding: 0.25rem 0;
	font-size: 0.9rem;
	color: $gray-500;
}

.sidenav_item .dash-text {
	margin-inline-start: 0.5rem;
	color: $gray-500;
}

.sidenav_icon {
	flex: 0 0 22px;
	width: 22px;
}

.sidenav_icon svg {
	width: 100%;
	color: $gray-500;
}

.sidenav_arrow svg path {
	stroke: $gray-500;
}

.sidenav_arrow {
	position: absolute;
	inset-inline-end: 20px;
	transform: rotate(0deg);
	transition: 0.1s;
	width: 6px;
	right: 1px;
	svg {
		width: 100%;
	}
}

[dir="rtl"] .sidenav_arrow {
	transform: scale(-1, 1);
}
///////////////////////////////////// if the nav opend  ///////////////////////////////////////////
.opend .sidenav_link {
	justify-content: start;
}
// .opend .sidenav_item {
// 	background-color: red !important;
// 	width: 96%;
// }
.opend .sidenav_link {
	padding: 0.65rem 1.5rem;
}

.opend .dash-text {
	// display: block;
	visibility: visible;
	opacity: 1;
	width: auto;
	// transition: .4s;
	transition-delay: 0.2s;
}
.opend {
	.sidenav_dropdown {
		// padding: 0 calc(2rem + 12px);
		.dropdown_item {
			position: relative;
			padding: 5px 0;
			.dropdown_link {
				display: flex;
				color: $gray-500;
				transition: 0.2s;
				&:hover {
					color: #fff;
					transition: 0.2s;
				}
			}
			.active .dash-text {
				color: #fff;
			}
			.active svg {
				color: #fff;
			}
		}
	}
	.toggled {
		transform: rotate(90deg);
		transition: 0.1s;
	}
}

.sidenav_link:hover {
	color: #99a4b1 !important;
}

///////////////////////////////////// if the nav closed  ///////////////////////////////////////////
.closed {
	.sidenav_item {
		&:hover {
			width: $opendNav;
			transition: none;
			background-color: #424647;
			color: #fff;
			.dash-text {
				visibility: visible;
				opacity: 1;
				padding-left: 30px;
				width: 100%;
				transition: none;
				box-shadow: none;
				display: block;
				color: #fff;
			}
			svg {
				color: #fff;
			}

			.sidenav_dropdown {
				position: absolute;
				display: flex;
				justify-content: center;
				flex-direction: column;
				background-color: #2c302e;
				inset-inline-start: 70px;
				width: calc(100% - 70px);
				padding-left: 10px;
				z-index: 999999999;
				.dropdown_item {
					position: relative;
					padding: 10px;
					.sidenav_arrow {
						visibility: visible;
						opacity: 1;
						display: block;
						top: 50%;
						transform: translateY(-50%);
					}
					.dropdown_link.active {
						color: #fff;
						.sidenav_icon svg {
							color: #fff;
						}
					}
					.dropdown_link {
						width: 100%;
						margin: 0;
						display: block;
						color: $gray-500;
						transition: 0.4s;
						&:hover {
							color: #fff;
							transition: 0.4s;
						}
					}
					&:hover {
						.sidenav_arrow svg path {
							stroke: #fff;
							transition: 0.4s;
						}
					}
				}
			}
		}
	}

	.sidenav_link {
		padding-inline-start: 20px;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		.dash-text {
			display: none;
		}

		&:hover {
			transition: none;
			color: initial !important;
		}

		.sidenav_icon,
		.sidenav_icon svg {
			box-shadow: none;
		}
	}

	.sidenav_dropdown,
	.sidenav_arrow {
		display: none;
	}

	.sidenav_header {
		text-align: center;
	}
	svg {
		height: 18px;
		width: 18px;
	}
}

// active
.sidenav_link.active {
	position: relative;
}
.sidenav_link.active,
.sidenav_link.active svg,
.sidenav_link.active .dash-text {
	color: #fff;
}
.sidenav_link.active .sidenav_arrow svg path {
	stroke: #fff;
}

@media screen and (max-width: 992px) {
	.closed {
		display: none !important;
	}
}
